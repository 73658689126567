import Vue from 'vue'
import Vuex from 'vuex'
import * as api from '@/services/salesman'
import utilities from '@/services/utilities'
import _ from 'lodash'

Vue.use(Vuex)

export default {
	namespaced: true,
	state: {
		spinnerLoader: false,
		spinnerLoaderLabel: 'Obteniendo Información',
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {
		SET_STATE({ commit }, payload) {
			commit('SET_STATE', payload)
		},
		async GET({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo información',
			})
			return new Promise((resolve, reject) => {
				api
					.get(payload)
					.then((response) => {
						// console.log('response-->', response)
						resolve(response)
					})
					.catch((error) => {
						reject()
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
	},
	getters: {
		spinnerLoader: (state) => state.spinnerLoader,
		spinnerLoaderLabel: (state) => state.spinnerLoaderLabel,
	},
}
