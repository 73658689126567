import Vue from 'vue'
import Vuex from 'vuex'
import * as measurementUnits from '@/services/measurement-units'
import * as facturapi from '@/services/facturapi'
import utilities from '@/services/utilities'
import Swal from 'sweetalert2'

Vue.use(Vuex)

export default {
	namespaced: true,
	state: {
		spinnerLoader: false,
		spinnerLoaderLabel: '',
		measurementUnitsList: [],
		searchMeasurementUnitsList: [],
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {
		GET({ commit, dispatch, rootState }, globalLoader = false) {
			if (globalLoader) {
				commit(
					'SET_STATE',
					{
						spinnerLoader: true,
						spinnerLoaderLabel: 'Obteniendo información',
					},
					{ root: true },
				)
			} else {
				commit('SET_STATE', {
					spinnerLoader: true,
					spinnerLoaderLabel: 'Obteniendo información',
				})
			}

			return new Promise((resolve, reject) => {
				measurementUnits
					.get()
					.then((response) => {
						commit('SET_STATE', {
							measurementUnitsList: response.data,
						})
						resolve(response)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Unidad de medida',
							description: utilities.objectValidate(error, 'response.data.message', 'Error al consultar el registro'),
						})
						commit('SET_STATE', {
							measurementUnitsList: [],
						})
					})
					.finally(() => {
						if (globalLoader) {
							commit(
								'SET_STATE',
								{
									spinnerLoader: false,
								},
								{ root: true },
							)
						} else {
							commit('SET_STATE', {
								spinnerLoader: false,
							})
						}
					})
			})
		},
		CREATE({ commit, dispatch, rootState }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})
			return new Promise((resolve, reject) => {
				measurementUnits
					.create(payload)
					.then((response) => {
						Swal.fire({
							title: 'Catálogo de unidades de medida',
							text: utilities.objectValidate(response, 'message', 'Registro actualizado con éxito.'),
							icon: 'success',
							confirmButtonText: 'Ok',
						}).then(() => {
							resolve()
							dispatch('GET')
						})
					})
					.catch((error) => {
						Swal.fire({
							title: 'Catálogo de unidades de medida',
							text: utilities.objectValidate(error, 'response.data.message', 'Error al obtener la información.'),
							icon: 'error',
							confirmButtonText: 'Ok',
						}).then(() => {
							reject(error)
						})
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		TOGGLE({ commit, dispatch, rootState }, id) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})
			measurementUnits
				.toggle(id)
				.then((response) => {
					Swal.fire({
						title: 'Catálogo de unidades de medida',
						text: utilities.objectValidate(response, 'message', 'Registro actualizado con éxito.'),
						icon: 'error',
						confirmButtonText: 'Ok',
					}).then(() => {
						dispatch('GET')
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Unidades de Medida',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al actualizar el registro'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		SEARCH_UNITS({ commit }, query) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo datos...',
			})

			facturapi
				.searchUnits(query)
				.then((response) => {
					// console.log('store/facturapi::SEARCH_UNITS::response-->', response)
					commit('SET_STATE', {
						searchMeasurementUnitsList: response,
					})
				})
				.catch((error) => {
					Swal.fire({
						title: 'Catálogo de unidades de medida',
						text: utilities.objectValidate(error, 'response.data.message', 'Error al obtener la información.'),
						icon: 'error',
						confirmButtonText: 'Ok',
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
	},
	getters: {
		measurementUnitsList: (state) => state.measurementUnitsList,
		searchMeasurementUnitsList: (state) => state.searchMeasurementUnitsList,
	},
}
