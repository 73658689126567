import Vue from 'vue'
import Vuex from 'vuex'
import * as branchOffices from '@/services/branch-offices'

Vue.use(Vuex)

export default {
	namespaced: true,
	state: {
		spinnerLoader: false,
		spinnerLoaderLabel: '',
		branchOfficesList: [],
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {
		GET({ commit, dispatch, rootState }) {
			commit('SET_STATE', {
				spinnerLoader: true,
			})
			branchOffices
				.get()
				.then((response) => {
					commit('SET_STATE', {
						branchOfficesList: response.data,
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Sucursales',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al consultar el registro'),
					})
					commit('SET_STATE', {
						branchOfficesList: [],
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		CREATE({ commit, dispatch, rootState }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})
			return new Promise((resolve, reject) => {
				branchOffices
					.create(payload)
					.then((response) => {
						Vue.prototype.$notification.success({
							message: 'Sucursales',
							description: response.message,
						})
						dispatch('GET')
						resolve(response)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Sucursales',
							description: utilities.objectValidate(error, 'response.data.message', 'Error al generar el registro'),
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},

		UPDATE({ commit, dispatch, rootState }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})

			return new Promise((resolve, reject) => {
				branchOffices
					.update(payload)
					.then((response) => {
						Vue.prototype.$notification.success({
							message: 'Sucursales',
							description: response.message,
						})
						dispatch('GET')
						resolve(response)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Sucursales',
							description: utilities.objectValidate(error, 'response.data.message', 'Error al actualizar el registro'),
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		DISABLE({ commit, dispatch, rootState }, id) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})
			branchOffices
				.disable(id)
				.then((response) => {
					Vue.prototype.$notification.success({
						message: 'Sucursales',
						description: response.message,
					})
					dispatch('GET')
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Sucursales',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al deshabilitar el registro'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		ENABLE({ commit, dispatch, rootState }, id) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})
			branchOffices
				.enable(id)
				.then((response) => {
					Vue.prototype.$notification.success({
						message: 'Sucursales',
						description: response.message,
					})
					dispatch('GET')
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Sucursales',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al habilitar el registro'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
	},
	getters: {
		branchOfficesList: (state) => state.branchOfficesList,
	},
}
