import apiClient from '@/services/axios'
import utilities from '@/services/utilities'

export async function get(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post('/loan-control', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function upload(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post(`/loan-control/upload`, payload, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function getFileUrl(id) {
	return new Promise((resolve, reject) => {
		return apiClient
			.get(`/loan-control/${id}`)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}
