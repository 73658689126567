import Vue from 'vue'
import Vuex from 'vuex'
import * as cashFlow from '@/services/cash-flow'
import utilities from '@/services/utilities'
import Swal from 'sweetalert2'
import router from '@/router'
import numeral from 'numeral'
import moment from 'moment'
import _ from 'lodash'

Vue.use(Vuex)

const cashFlowTypes = ['cash', 'bank_transfer', 'bank_terminal', 'other', 'paypal', 'mercadopago']
const dateFormat = 'YYYY-MM-DD'

export default {
	namespaced: true,
	state: {
		spinnerLoader: false,
		spinnerLoaderLabel: '',
		cfList: [],
		cashFlowList: {
			transactions: [],
			general: {
				in: 0,
				out: 0,
			},
			cash: {
				in: 0,
				out: 0,
			},
			digital: {
				in: 0,
				out: 0,
			},
		},
		generalCashFlow: {
			global: {
				general: {
					in: 0,
					out: 0,
				},
				cash: {
					in: 0,
					out: 0,
				},
				bank: {
					in: 0,
					out: 0,
				},
				mercadopago: {
					in: 0,
					out: 0,
				},
			},
		},
		actualRecord: {},
		spendingAuthorizationList: {
			available_cash: 0,
			available_transfer: 0,
			available_bank_terminal: 0,
			available_paypal: 0,
			available_mercadopago: 0,
			available_others: 0,
			bank_terminal_income: 0,
			bank_transfer_income: 0,
			paypal_income: 0,
			mercadopago_income: 0,
			cash_income: 0,
			other_income: 0,
		},
		filters: {
			defaultDate: [moment().startOf('month').format(dateFormat), moment().endOf('month').format(dateFormat)],
			cashier: null,
			paymentMethod: null,
		},
		cashFlowSumary: {
			in: 0,
			out: 0,
		},
		summaryData: {},
		ticketData: {
			id: '',
			cashier: '',
			created_at: '',
			total: '',
			totalAdvances: '',
			advances: {
				payment_cash: '',
				payment_bank_terminal: '',
				payment_transfer: '',
				payment_paypal: '',
				payment_mercadopago: '',
			},
			payment_cash: '',
			payment_bank_terminal: '',
			payment_transfer: '',
			payment_paypal: '',
			payment_mercadopago: '',
		},
		fileURL: '',
		modalFileVisible: false,
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {
		GET_FLOW({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo información',
			})
			cashFlow
				.getFlow(payload)
				.then((response) => {
					//
					let data = response.data.map((element, index) => {
						let total = cashFlowTypes.reduce((accumulator, currentValue) => {
							return accumulator + utilities.objectValidate(element, currentValue, 0)
						}, 0)

						return {
							...element,
							index,
							user_information: JSON.parse(utilities.objectValidate(element, 'user_information', {})),
							total: numeral(total).format('$0,0.00'),
						}
					})
					commit('SET_STATE', {
						cfList: _.cloneDeep(data),
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Cashflow',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al consultar el servicio'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		GET({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo información',
			})
			cashFlow
				.get(payload)
				.then((response) => {
					//
					commit('SET_STATE', {
						generalCashFlow: _.cloneDeep(response.data),
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Flujos de caja',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al consultar el servicio'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		SET_LOCAL({ commit, state }, { index, requireRouter }) {
			let actualRecord = _.cloneDeep(state.cfList[index])
			commit('SET_STATE', {
				actualRecord,
			})
			if (requireRouter) {
				router.push(`/operations/cash-flow/${actualRecord.id}`)
			}
		},
		async CREATE_BY_CASHIER({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Generando registro',
			})
			return new Promise((resolve, reject) => {
				cashFlow
					.createByCashier(payload)
					.then((response) => {
						//
						Swal.fire({
							title: 'Flujos de caja',
							text: utilities.objectValidate(response, 'message', 'Registro generado con éxito'),
							icon: 'success',
							confirmButtonText: 'Ok',
						}).then(() => {
							commit('SET_STATE', {
								ticketData: _.cloneDeep(response.data),
							})
							resolve(response.data)
						})
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Flujos de caja',
							description: utilities.objectValidate(error, 'response.data.message', 'Error al generar el registro'),
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		TICKET_BY_CASHIER({ commit, dispatch }, id) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Generando registro',
			})
			cashFlow
				.ticketByCashier(id)
				.then((response) => {
					//
					commit('SET_STATE', {
						ticketData: _.cloneDeep(response.data),
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Flujos de caja',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al generar el registro'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		async CREATE({ commit, dispatch }, { payload, fileList, filePaymentReceipt }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Generando registro',
			})
			return new Promise((resolve, reject) => {
				cashFlow
					.create(payload)
					.then((responseCreate) => {
						//
						Swal.fire({
							title: 'Flujos de caja',
							text: utilities.objectValidate(responseCreate, 'message', 'Registro generado con éxito'),
							icon: 'success',
							confirmButtonText: 'Ok',
						}).then(async () => {
							// Redirigimos si el motivo es diferente a una devolución de ticket
							// Cancelación/Devolución de ticket de venta (id: 18)
							// if (payload.cashflow_action_id == 18) {
							// 	//

							// 	if (fileList.length) {
							// 		let formData = new FormData()
							// 		formData.append('file', fileList[0])
							// 		formData.append('id', responseCreate.data.id)
							// 		formData.append('filetype', 'documentation')
							// 		await dispatch('UPLOAD_FILE', formData)
							// 	}

							// 	if (filePaymentReceipt.length) {
							// 		let formData = new FormData()
							// 		formData.append('file', filePaymentReceipt[0])
							// 		formData.append('id', responseCreate.data.id)
							// 		formData.append('filetype', 'paymentReceipt')
							// 		await dispatch('UPLOAD_FILE', formData)
							// 	}
							// } else {
							// 	dispatch('GET')
							// 	router.push('/operations/cash-flow')
							// }
							commit('SET_STATE', {
								ticketData: _.cloneDeep(responseCreate.data),
							})
							resolve(responseCreate)
						})
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Flujos de caja',
							description: utilities.objectValidate(error, 'response.data.message', 'Error al generar el registro'),
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		UPDATE({ commit, dispatch }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando registro',
			})
			cashFlow
				.update(payload)
				.then((response) => {
					//
					Swal.fire({
						title: 'Flujos de caja',
						text: utilities.objectValidate(response, 'message', 'Registro actualizado con éxito'),
						icon: 'success',
						confirmButtonText: 'Ok',
					}).then(() => {
						dispatch('GET')
						router.push('/operations/cash-flow')
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Flujos de caja',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al actualizar el registro'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		CONFIRM({ commit, dispatch }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})
			new Promise((resolve) => {
				cashFlow
					.confirm(payload)
					.then((response) => {
						//
						Swal.fire({
							title: 'Flujos de caja',
							text: utilities.objectValidate(response, 'message', 'Operación confirmada con éxito'),
							icon: 'success',
							confirmButtonText: 'Ok',
						}).then(() => {
							dispatch('GET')
							resolve(response)
						})
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Flujos de caja',
							description: utilities.objectValidate(error, 'response.data.message', 'Error al confirmar la operación'),
						})
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		GET_SUMMARY({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo información',
			})

			cashFlow
				.getSummary(payload)
				.then((response) => {
					//
					commit('SET_STATE', {
						spendingAuthorizationList: response.data,
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Flujos de caja',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al obtener la información'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		OPERATIONS_GET_SUMMARY({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo información',
			})

			cashFlow
				.getSummary(payload)
				.then((response) => {
					//
					commit('SET_STATE', {
						summaryData: response.data,
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Flujos de caja',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al obtener la información'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		async UPLOAD_FILE({ commit, dispatch, state }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Procesando archivo...',
			})

			return new Promise((resolve, reject) => {
				return cashFlow
					.uploadFile(payload)
					.then((response) => {
						//
						Swal.fire({
							title: 'Flujos de caja',
							text: utilities.objectValidate(response, 'message', 'Recibo adjunto con éxito'),
							icon: 'success',
							confirmButtonText: 'Ok',
						}).then(() => {
							if (utilities.objectValidate(state, 'actualRecord.id')) {
								dispatch('GET_FILE_URL', state.actualRecord.id)
							}
							resolve()
						})
					})
					.catch((error) => {
						Swal.fire({
							title: 'Flujos de caja',
							text: utilities.objectValidate(error, 'response.data.message', 'Error al subir el comprobante'),
							icon: 'error',
							confirmButtonText: 'Ok',
						})
						reject()
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
							modalFileVisible: false,
						})
					})
			})
		},
		RESET_ONE_FILTER({ commit, state, dispatch }, key) {
			let filters = {
				...state.filters,
			}

			if (key == 'defaultDate') {
				filters.defaultDate = [moment().startOf('month').format(dateFormat), moment().endOf('month').format(dateFormat)]
				dispatch('GET_FLOW', {
					startDate: filters.defaultDate[0],
					endDate: filters.defaultDate[1],
				})
			} else {
				filters[key] = 'Todos'
			}

			commit('SET_STATE', {
				filters: _.cloneDeep(filters),
			})
		},
		SPENDING_AUTHORIZATIONS({ commit, state }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo información...',
			})

			cashFlow
				.spendingAuthorizations(payload)
				.then((response) => {
					//
					commit('SET_STATE', {
						cashFlowList: _.cloneDeep(response.data.cashFlow.filter((e) => e.type == 'out')),
						cashFlowSumary: _.cloneDeep(response.data.summary),
					})
				})
				.catch((error) => {
					Swal.fire({
						title: 'Flujos de caja',
						text: utilities.objectValidate(error, 'response.data.message', 'Error al obtener la información'),
						icon: 'error',
						confirmButtonText: 'Ok',
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		SET_SPENDING_AUTHORIZATIONS({ commit, dispatch }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando registro...',
			})

			cashFlow
				.setSpendingAuthorizations(payload)
				.then((response) => {
					//
					Swal.fire({
						title: 'Flujos de caja',
						text: utilities.objectValidate(response, 'message', 'Operación validada con éxito'),
						icon: 'success',
						confirmButtonText: 'Ok',
					}).then(() => {
						dispatch('SPENDING_AUTHORIZATIONS')
					})
				})
				.catch((error) => {
					Swal.fire({
						title: 'Flujos de caja',
						text: utilities.objectValidate(error, 'response.data.message', 'Error al obtener la información'),
						icon: 'error',
						confirmButtonText: 'Ok',
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		GET_FILE_URL({ commit, state }, id) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo datos',
				fileURL: '',
				actualRecord: _.cloneDeep(state.cfList.find((e) => e.id == id)),
			})
			cashFlow
				.getFileUrl(id)
				.then((response) => {
					commit('SET_STATE', {
						fileURL: response.data.url,
						modalFileVisible: true,
					})
				})
				.catch((error) => {
					//
					commit('SET_STATE', {
						modalFileVisible: true,
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		SWAP_MONEY({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Procesando...',
			})
			cashFlow
				.swapMoney(payload)
				.then((response) => {
					Swal.fire({
						title: 'Flujos de caja',
						text: utilities.objectValidate(response, 'message', 'Se ha generado el traslado de manera correcta'),
						icon: 'success',
						confirmButtonText: 'Continuar',
					}).then(() => {
						commit('SET_STATE', {
							modalFileVisible: true,
						})
					})
				})
				.catch((error) => {
					Swal.fire({
						title: 'Flujos de caja',
						text: utilities.objectValidate(error, 'response.data.message', 'Error al procesar la información'),
						icon: 'error',
						confirmButtonText: 'Ok',
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
	},
	getters: {
		cashFlowList: (state) => state.cashFlowList,
		actualRecord: (state) => state.actualRecord,
		filters: (state) => state.filters,
		spendingAuthorizationList: (state) => state.spendingAuthorizationList,
		spinnerLoader: (state) => state.spinnerLoader,
		spinnerLoaderLabel: (state) => state.spinnerLoaderLabel,
		cashFlowSumary: (state) => state.cashFlowSumary,
		summaryData: (state) => state.summaryData,
		cfList: (state) => state.cfList,
		ticketData: (state) => state.ticketData,
		fileURL: (state) => state.fileURL,
		modalFileVisible: (state) => state.modalFileVisible,
		generalCashFlow: (state) => state.generalCashFlow,
	},
}
