import apiClient from '@/services/axios'
import utilities from '@/services/utilities'

export async function getAll(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post('/file-manager/list', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function crateFolder(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post('/file-manager/create', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function upload(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post(`/file-manager/upload`, payload, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function getFileUrl(id) {
	return new Promise((resolve, reject) => {
		return apiClient
			.get(`/file-manager/${id}`)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}
