import Vue from 'vue'
import Vuex from 'vuex'
import * as api from '@/services/recurringPayments'
import utilities from '@/services/utilities'

Vue.use(Vuex)

export default {
	namespaced: true,
	state: {
		spinnerLoader: false,
		spinnerLoaderLabel: '',
		recurringPaymentsList: [],
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {
		GET({ commit, state }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
			})

			return new Promise((resolve, reject) => {
				api
					.get(payload)
					.then((response) => {
						state.recurringPaymentsList = _.cloneDeep(response.data)
						resolve(response.data)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Citas',
							description: utilities.objectValidate(error, 'response.data.message', 'Error al consultar el registro'),
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		GET_BY_GROUP({ commit }, id) {
			commit('SET_STATE', {
				spinnerLoader: true,
			})

			return new Promise((resolve, reject) => {
				api
					.getByGroup(id)
					.then((response) => {
						resolve(response)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Citas',
							description: utilities.objectValidate(error, 'response.data.message', 'Error al consultar el registro'),
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		CREATE({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
			})

			return new Promise((resolve, reject) => {
				api
					.create(payload)
					.then((response) => {
						resolve(response)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Citas',
							description: utilities.objectValidate(error, 'response.data.message', 'Error al generar el registro'),
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		DELETE({ commit }, id) {
			commit('SET_STATE', {
				spinnerLoader: true,
			})

			return new Promise((resolve, reject) => {
				api
					.deleteElement(id)
					.then((response) => {
						resolve(response)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Citas',
							description: utilities.objectValidate(error, 'response.data.message', 'Error al generar el registro'),
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
	},
	getters: {
		spinnerLoader: (state) => state.spinnerLoader,
		spinnerLoaderLabel: (state) => state.spinnerLoaderLabel,
		recurringPaymentsList: (state) => state.recurringPaymentsList,
	},
}
