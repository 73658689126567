import apiClient from '@/services/axios'
import utilities from '@/services/utilities'

export async function getDashboardReport(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post('/direction', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function getTechnicProfit(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post('/direction/technic-profit', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function getCashBalance(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post('/direction/get-cash-balance', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function getLoanPurchaseOrders() {
	return new Promise((resolve, reject) => {
		return apiClient
			.get('/direction/loan-purchase-orders')
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function getProductivity() {
	return new Promise((resolve, reject) => {
		return apiClient
			.get('/direction/productivity')
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}
