import apiClient from '@/services/axios'
import utilities from '@/services/utilities'

export async function get(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post('/recurring-payments', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function getByGroup(id) {
	return new Promise((resolve, reject) => {
		return apiClient
			.get(`/recurring-payments/group/${id}`)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function create(payload) {
	return new Promise((resolve, reject) => {
		return apiClient
			.post('/recurring-payments/create', payload)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}
export async function deleteElement(id) {
	return new Promise((resolve, reject) => {
		return apiClient
			.delete(`/recurring-payments/${id}`)
			.then((response) => {
				resolve(utilities.objectValidate(response, 'data', {}))
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}
