import Vue from 'vue'
import Vuex from 'vuex'
import * as api from '@/services/loanControl'
import utilities from '@/services/utilities'
import Swal from 'sweetalert2'
import _ from 'lodash'

Vue.use(Vuex)

export default {
	namespaced: true,
	state: {
		spinnerLoader: false,
		spinnerLoaderLabel: 'Obteniendo Información',
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {
		SET_STATE({ commit }, payload) {
			commit('SET_STATE', payload)
		},
		async GET({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo información',
			})
			return new Promise((resolve, reject) => {
				api
					.get(payload)
					.then((response) => {
						// console.log('response-->', response)
						resolve(response)
					})
					.catch((error) => {
						reject()
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		async UPLOAD({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Generando registro',
			})
			return new Promise((resolve, reject) => {
				api
					.upload(payload)
					.then((response) => {
						resolve(response)
					})
					.catch((error) => {
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		async GET_FILE_URL({ commit }, { id, action }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo datos',
			})
			return new Promise((resolve, reject) => {
				api
					.getFileUrl(id)
					.then((response) => {
						if (action == 'download') {
							window.open(response.data.url)
						} else {
							resolve({
								fileURL: _.cloneDeep(response.data.url),
							})
						}
					})
					.catch((error) => {
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
	},
	getters: {
		spinnerLoader: (state) => state.spinnerLoader,
		spinnerLoaderLabel: (state) => state.spinnerLoaderLabel,
	},
}
