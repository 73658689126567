import apiClient from '@/services/axios'
import utilities from '@/services/utilities'

export async function getModels(brand_id) {
	return new Promise((resolve, reject) => {
		// console.log('actions:motorcycles:getModels', brand_id)
		return apiClient
			.get(`/taller/motorcycle-brands/${brand_id}/models`)
			.then((response) => {
				// console.log('actions:motorcycles:getModels:response', response.data)
				if (response.data) {
					resolve(utilities.objectValidate(response, 'data', {}))
				}
				return false
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function getOneModel(motorcycle_model_id) {
	return new Promise((resolve, reject) => {
		// console.log('actions:motorcycles:getOneModel', brand_id)
		return apiClient
			.get(`/taller/motorcycle-models/${motorcycle_model_id}`)
			.then((response) => {
				// console.log('actions:motorcycles:getOneModel:response', response.data)
				if (response.data) {
					resolve(utilities.objectValidate(response, 'data', {}))
				}
				return false
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function getAllModels() {
	return new Promise((resolve, reject) => {
		//console.log('actions:motorcycles:getAllModels')
		return apiClient
			.get(`/taller/motorcycle-models`)
			.then((response) => {
				//console.log('actions:motorcycles:getAllModels:response', response.data)
				if (response.data) {
					resolve(utilities.objectValidate(response, 'data', {}))
				}
				return false
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function createModel(payload) {
	return new Promise((resolve, reject) => {
		// console.log('actions:motorcycles:createModel', brand_id)
		return apiClient
			.post(`/taller/motorcycle-models`, payload)
			.then((response) => {
				// console.log('actions:motorcycles:createModel:response', response.data)
				if (response.data) {
					resolve(utilities.objectValidate(response, 'data', {}))
				}
				return false
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}

export async function updateModel(payload) {
	return new Promise((resolve, reject) => {
		// console.log('actions:motorcycles:updateModel', brand_id)
		return apiClient
			.put(`/taller/motorcycle-models`, payload)
			.then((response) => {
				// console.log('actions:motorcycles:updateModel:response', response.data)
				if (response.data) {
					resolve(utilities.objectValidate(response, 'data', {}))
				}
				return false
			})
			.catch((err) => {
				console.log(err)
				reject(err)
			})
	})
}
