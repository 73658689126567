import Vue from 'vue'
import Vuex from 'vuex'
import user from './user'
import settings from './settings'
import purchaseOrders from './purchase-orders'
import expenseTypes from './expense-types'
import businessAreas from './business-areas'
import measurementUnits from './measurement-units'
import users from './users'
import suppliers from './suppliers'
import paymentMethods from './payment-methods'
import dashboard from './dashboard'
import bankingInstitutions from './banking-institutions'
import useOfCfdi from './use-of-cfdi'
import branchOffices from './branch-offices'
import serviceAdministration from './service-administration'
import priceTab from './price-tab'
import motorcycleBrand from './motorcycle-brands'
import motorcycleModels from './motorcycle-models'
import products from './products'
import reports from './reports'
import tireQuoter from './tire-quoter'
import warehouse from './warehouse'
import serviceOrders from './service-orders'
import cashFlow from './cash-flow'
import facturapi from './facturapi'
import pos from './pos'
import clients from './clients'
import debtsToPay from './debtsToPay'
import quotes from './quotes'
import billing from './billing'
import impressment from './impressment'
import leadership from './leadership'
import cashflowActions from './cashflow-actions'
import warehouseCashflow from './warehouse-cashflow'
import quotations from './quotations'
import direction from './direction'
import developmentRequests from './developmentRequests'
import complaints from './complaints'
import questionnaires from './questionnaires'
import notifications from './notifications'
import schedules from './schedules'
import binnacle from './binnacle'
import surveys from './surveys'
import recurringPayments from './recurringPayments'
import fileManager from './fileManager'
import savings from './savings'
import loanControl from './loanControl'
import salesman from './salesman'
import internalOrders from './internalOrders'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		user,
		settings,
		purchaseOrders,
		expenseTypes,
		businessAreas,
		measurementUnits,
		users,
		suppliers,
		paymentMethods,
		dashboard,
		bankingInstitutions,
		useOfCfdi,
		branchOffices,
		serviceAdministration,
		priceTab,
		motorcycleBrand,
		motorcycleModels,
		products,
		reports,
		tireQuoter,
		warehouse,
		serviceOrders,
		cashFlow,
		facturapi,
		pos,
		clients,
		debtsToPay,
		quotes,
		billing,
		impressment,
		leadership,
		cashflowActions,
		warehouseCashflow,
		quotations,
		direction,
		developmentRequests,
		complaints,
		questionnaires,
		notifications,
		schedules,
		binnacle,
		surveys,
		recurringPayments,
		fileManager,
		savings,
		loanControl,
		salesman,
		internalOrders,
	},
	state: {
		spinnerLoader: false,
		spinnerLoaderLabel: '',
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {},
})
