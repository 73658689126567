import Vue from 'vue'
import Vuex from 'vuex'
//
import * as directionAPI from '@/services/direction'
import utilities from '@/services/utilities'
import Swal from 'sweetalert2'
import _ from 'lodash'
import moment from 'moment'
//
const dateFormat = 'YYYY-MM-DD'

Vue.use(Vuex)

export default {
	namespaced: true,
	state: {
		spinnerLoader: false,
		spinnerLoaderLabel: 'Obteniendo información',
		dashboardReport: {
			semestralGoal: 0,
			average: 0,
			panel: {
				in: 0,
				out: 0,
			},
			pacific: {
				in: 0,
				out: 0,
			},
			rls: {
				in: 0,
				out: 0,
			},
			loans: {
				amount: 0,
				paymentNumber: 0,
				paymentTotal: 0,
			},
		},
		getTechnicProfitReport: {},
		filters: {
			defaultDate: [moment().startOf('month').format(dateFormat), moment().endOf('month').format(dateFormat)],
		},
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {
		GET_DASHBOARD_REPORT({ state, commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
			})

			directionAPI
				.getDashboardReport(payload)
				.then((response) => {
					commit('SET_STATE', {
						dashboardReport: {
							...state.dashboardReport,
							..._.cloneDeep(response.data),
						},
					})
				})
				.catch((error) => {
					console.log(error)
					Swal.fire({
						title: 'Dirección',
						text: utilities.objectValidate(error, 'response.data.message', 'Error al obtener la información.'),
						icon: 'error',
						confirmButtonText: 'Ok',
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		GET_TECHNIC_PROFIT({ commit }, payload) {
			// console.log('GET_TECHNIC_PROFIT')
			commit('SET_STATE', {
				spinnerLoader: true,
			})

			directionAPI
				.getTechnicProfit(payload)
				.then((response) => {
					commit('SET_STATE', {
						getTechnicProfitReport: _.cloneDeep(response.data),
					})
				})
				.catch((error) => {
					console.log(error)
					Swal.fire({
						title: 'Dirección',
						text: utilities.objectValidate(error, 'response.data.message', 'Error al obtener la información.'),
						icon: 'error',
						confirmButtonText: 'Ok',
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		async GET_CASH_BALANCE({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
			})

			return new Promise((resolve, reject) => {
				directionAPI
					.getCashBalance(payload)
					.then((response) => {
						resolve(response)
					})
					.catch((error) => {
						console.log(error)
						Swal.fire({
							title: 'Dirección',
							text: utilities.objectValidate(error, 'response.data.message', 'Error al obtener la información.'),
							icon: 'error',
							confirmButtonText: 'Ok',
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		async GET_LOAN_PURCHASE_ORDERS({ commit }) {
			commit('SET_STATE', {
				spinnerLoader: true,
			})

			return new Promise((resolve, reject) => {
				directionAPI
					.getLoanPurchaseOrders()
					.then((response) => {
						resolve(response)
					})
					.catch((error) => {
						console.log(error)
						Swal.fire({
							title: 'Dirección',
							text: utilities.objectValidate(error, 'response.data.message', 'Error al obtener la información.'),
							icon: 'error',
							confirmButtonText: 'Ok',
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		async GET_PRODUCTIVITY({ commit }) {
			commit('SET_STATE', {
				spinnerLoader: true,
			})

			return new Promise((resolve, reject) => {
				directionAPI
					.getProductivity()
					.then((response) => {
						resolve(response)
					})
					.catch((error) => {
						console.log(error)
						Swal.fire({
							title: 'Dirección',
							text: utilities.objectValidate(error, 'response.data.message', 'Error al obtener la información.'),
							icon: 'error',
							confirmButtonText: 'Ok',
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
	},
	getters: {
		spinnerLoader: (state) => state.spinnerLoader,
		spinnerLoaderLabel: (state) => state.spinnerLoaderLabel,
		dashboardReport: (state) => state.dashboardReport,
		getTechnicProfitReport: (state) => state.getTechnicProfitReport,
		filters: (state) => state.filters,
	},
}
