<template>
	<div :class="$style.footer">
		<div :class="$style.footerInner">
			<a href="https://s2r.mx" target="_blank" rel="noopener noreferrer" :class="$style.logo"> Holding Administración</a>
			<br />
			<p class="mb-0">
				Todos los Derechos Reservados
				<br />
				v.{{ appVersion }}
			</p>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			appVersion: process.env.VUE_APP_VERSION,
		}
	},
}
</script>
<style lang="scss" module>
@import './style.module.scss';
</style>
