import Vue from 'vue'
import * as api from '@/services/fileManager'
import utilities from '@/services/utilities'
import moment from 'moment'
import _ from 'lodash'

export default {
	namespaced: true,
	state: {
		spinnerLoader: false,
		spinnerLoaderLabel: '',
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {
		async GET({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo información',
			})
			return new Promise((resolve, reject) => {
				api
					.getAll(payload)
					.then((response) => {
						resolve(response)
					})
					.catch((error) => {
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		async CREATE_FOLDER({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Generando registro',
			})
			return new Promise((resolve, reject) => {
				api
					.crateFolder(payload)
					.then((response) => {
						resolve(response)
					})
					.catch((error) => {
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		async UPLOAD({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Generando registro',
			})
			return new Promise((resolve, reject) => {
				api
					.upload(payload)
					.then((response) => {
						resolve(response)
					})
					.catch((error) => {
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		async GET_FILE_URL({ commit }, { id, action }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo datos',
			})
			return new Promise((resolve, reject) => {
				api
					.getFileUrl(id)
					.then((response) => {
						if (action == 'download') {
							window.open(response.data.url)
						} else {
							resolve({
								fileURL: _.cloneDeep(response.data.url),
							})
						}
					})
					.catch((error) => {
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
	},
	getters: {
		spinnerLoader: (state) => state.spinnerLoader,
		spinnerLoaderLabel: (state) => state.spinnerLoaderLabel,
	},
}
