import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
import * as suppliers from '@/services/suppliers'
import Swal from 'sweetalert2'
import _ from 'lodash'
import utilities from '@/services/utilities'

const provider = {
	getSuppliers: suppliers.getSuppliers,
	getOneSupplier: suppliers.getOneSupplier,
	createSupplier: suppliers.createSupplier,
	updateSupplier: suppliers.updateSupplier,
	uploadFile: suppliers.uploadFile,
}

Vue.use(Vuex)

export default {
	namespaced: true,
	state: {
		spinnerLoader: false,
		spinnerLoaderLabel: '',
		suppliersList: [],
		actualSupplier: {},
		fileURL: '',
		modalFileVisible: false,
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {
		async NEW({ dispatch, rootState }) {
			if (!rootState.bankingInstitutions.bankingInstitutionsList.length) {
				await dispatch('bankingInstitutions/GET', null, { root: true })
			}
			router.push('/catalogs/supplier/new')
		},
		GET_NO_REDIRECT({ commit }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo información',
			})

			provider
				.getSuppliers()
				.then((response) => {
					commit('SET_STATE', {
						suppliersList: _.cloneDeep(response.data),
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Error',
						description: 'Error al obtener el listado de proveedores',
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		GET({ commit }, globalLoader = false) {
			if (globalLoader) {
				commit(
					'SET_STATE',
					{
						spinnerLoader: true,
						spinnerLoaderLabel: 'Obteniendo información',
					},
					{ root: true },
				)
			} else {
				commit('SET_STATE', {
					spinnerLoader: true,
					spinnerLoaderLabel: 'Obteniendo información',
				})
			}

			provider
				.getSuppliers()
				.then((response) => {
					commit('SET_STATE', {
						suppliersList: _.cloneDeep(response.data),
					})
				})
				.catch((error) => {
					console.log('error-->', error)
					Vue.prototype.$notification.error({
						message: 'Error',
						description: 'Error al obtener el listado de proveedores',
					})
				})
				.finally(() => {
					if (globalLoader) {
						commit(
							'SET_STATE',
							{
								spinnerLoader: false,
							},
							{ root: true },
						)
					} else {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					}
				})
		},
		async GET_ONE({ commit, dispatch, rootState }, id) {
			if (!rootState.bankingInstitutions.bankingInstitutionsList.length) {
				await dispatch('bankingInstitutions/GET', null, { root: true })
			}

			commit('SET_STATE', {
				spinnerLoader: true,
			})

			provider
				.getOneSupplier(id)
				.then((response) => {
					commit('SET_STATE', {
						actualSupplier: _.cloneDeep(response.data),
					})
					router.push(`/catalogs/supplier/${id}`)
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Error',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al obtener el registro del proveedor'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		async GET_ONE_NO_REDIRECT({ commit, dispatch, rootState }, id) {
			if (!rootState.bankingInstitutions.bankingInstitutionsList.length) {
				await dispatch('bankingInstitutions/GET', null, { root: true })
			}

			commit('SET_STATE', {
				spinnerLoader: true,
			})

			return new Promise((resolve, reject) => {
				provider
					.getOneSupplier(id)
					.then((response) => {
						commit('SET_STATE', {
							actualSupplier: _.cloneDeep({
								...response.data,
								payment_methods: response.data.payment_methods.map((e) => {
									return {
										...e,
										bankName: Number(e.banking_institution) > 0 ? rootState.bankingInstitutions.bankingInstitutionsList.find((b) => b.id == e.banking_institution).bank_name : '- No aplica -',
									}
								}),
							}),
						})
						resolve(response)
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Error',
							description: utilities.objectValidate(error, 'response.data.message', 'Error al obtener el registro del proveedor'),
						})
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		CREATE({ commit, dispatch }, { payload, redirect, formData }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})
			return new Promise((resolve, reject) => {
				provider
					.createSupplier(payload)
					.then(async (response) => {
						if (formData) {
							formData.append('supplier_id', response.data.supplier_id)
							await provider.uploadFile('file', formData)
						}
						Swal.fire({
							title: 'Proveedores',
							text: response.message || 'Datos actualizados con éxito',
							icon: 'success',
							confirmButtonText: 'Ok',
						}).then(() => {
							dispatch('GET')
							if (redirect) {
								router.push('/catalogs/suppliers')
							} else {
								resolve(response.data)
							}
						})
					})
					.catch((error) => {
						Vue.prototype.$notification.error({
							message: 'Error',
							description: 'Error al crear el registro de proveedores',
						})
						reject()
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		UPDATE({ commit, dispatch }, { payload, formData }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información',
			})
			provider
				.updateSupplier(payload)
				.then(async (response) => {
					if (formData) {
						formData.append('supplier_id', payload.id)
						await provider.uploadFile('csf', formData)
					}
					Swal.fire({
						title: 'Proveedores',
						text: response.message || 'Datos actualizados con éxito',
						icon: 'success',
						confirmButtonText: 'Ok',
					}).then(() => {
						dispatch('GET')
						router.push('/catalogs/suppliers')
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Error',
						description: 'Error al actualizar el registro de proveedores',
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		DELETE({ commit, dispatch }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Actualizando información...',
			})
			suppliers
				.deleteRecord(payload)
				.then((response) => {
					Swal.fire({
						title: 'Proveedores',
						text: response.message || 'Registro eliminado con éxito',
						icon: 'success',
						confirmButtonText: 'Ok',
					}).then(() => {
						dispatch('GET')
					})
				})
				.catch((error) => {
					Vue.prototype.$notification.error({
						message: 'Error',
						description: utilities.objectValidate(error, 'response.data.message', 'Error al actualizar el registro'),
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		GET_FILE_URL({ commit }, { id, action }) {
			commit('SET_STATE', {
				spinnerLoader: true,
				spinnerLoaderLabel: 'Obteniendo datos',
				fileURL: '',
			})
			suppliers
				.getFileUrl(id)
				.then((response) => {
					if (action == 'download') {
						window.open(response.data.url)
					} else {
						commit('SET_STATE', {
							fileURL: _.cloneDeep(response.data.url),
							modalFileVisible: true,
						})
					}
				})
				.catch((error) => {
					Swal.fire({
						title: 'Ordenes de compra',
						text: utilities.objectValidate(error, 'response.data.message', 'Error al obtener el registro.'),
						icon: 'error',
						confirmButtonText: 'Ok',
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
	},
	getters: {
		suppliersList: (state) => state.suppliersList,
		actualSupplier: (state) => state.actualSupplier,
		spinnerLoaderLabel: (state) => state.spinnerLoaderLabel,
		spinnerLoader: (state) => state.spinnerLoader,
		fileURL: (state) => state.fileURL,
		modalFileVisible: (state) => state.modalFileVisible,
	},
}
